import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import './plugins/bootstrap-vue';
import App from './App.vue';
import VueAnalytics from 'vue-analytics';
import router from './router';
import firebase from 'firebase';
import VueGoogleCharts from 'vue-google-charts';
import { CarouselPlugin } from 'bootstrap-vue';
Vue.use(CarouselPlugin);
Vue.use(VueGoogleCharts);
import VueSweetalert2 from 'vue-sweetalert2';
import VueLazyload from 'vue-lazyload';
import shareIt from 'vue-share-it';
import * as VueGoogleMaps from 'vue2-google-maps';

import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
//importing vuecal css and js
import 'vue-cal/dist/vuecal.css';
import 'vue-datetime/dist/vue-datetime.css';
import 'vue-cal/dist/drag-and-drop';
import Vue2Editor from 'vue2-editor';
import { BootstrapVueIcons } from 'bootstrap-vue';
//SEO
import VueMeta from 'vue-meta';
Vue.use(Vue2Editor);

import 'firebase/auth';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
import { VBHover } from 'bootstrap-vue';
import './registerServiceWorker';

//tabsimport Vue from "vue";
import Tabs from "vue-material-tabs";
Vue.use(Tabs);

import { ToastPlugin } from 'bootstrap-vue'
Vue.use(ToastPlugin)

//multi-step-wizard
import VueStepWizard from 'vue-step-wizard'
import 'vue-step-wizard/dist/vue-step-wizard.css'
Vue.use(VueStepWizard);

// QR codes
import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)

//google
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDFI9tO_rsPTsbP4Rp2KlNUrInl64Ruy-I',
    libraries: 'places',
  },
  installComponents: true,
});

// Image added
Vue.use(VueLazyload);
Vue.use(shareIt);
Vue.use(BootstrapVueIcons);
// Note: Vue automatically prefixes the directive name with 'v-'
Vue.directive('b-hover', VBHover);
Vue.use(VueAnalytics, {
  id: 'G-E8P11JQD2B',
});
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDSQQ-3lhPwdRIdgh3-peUFSGWO5QykdEc',
  authDomain: 'mcmakelaardij-b0543.firebaseapp.com',
  projectId: 'mcmakelaardij-b0543',
  storageBucket: 'mcmakelaardij-b0543.appspot.com',
  messagingSenderId: '366989620318',
  appId: '1:366989620318:web:7804c964b92f91694d30cf',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
Vue.use(VueSweetalert2);
Vue.config.productionTip = false;
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

let app;

firebase.auth().onAuthStateChanged(async function () {
  if (!app) {
    new Vue({
      router,
      render: h => h(App),
    }).$mount('#app');
    app = true;
  }
});
