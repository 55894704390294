import Vue from 'vue';
import VueRouter from 'vue-router';
import firebase from 'firebase';
import axios from 'axios';

Vue.use(VueRouter);
const isLoggedIn = () => firebase.auth().currentUser;


/**
 * @type {import('vue-router').Route[]}
 */
const routes = [
 

  {
    path: '/',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "Login" */ '@/views/Login.vue'),
    meta: { title: ' MC MCmakelaardij| Login ' },
  },
{

    path: '*',
    name: 'NotFound',
    component: () =>
      import(/* webpackChunkName: "NotFound" */ '@/views/404.vue'),
    meta: { title: ' Mc makelaardij | Oeps pagina niet gevonden' },
  },
  // User portaal

  {
    path: '/Portaal',
    name: 'Portaal',
    component: () =>   
    
      import(
        /* webpackChunkName: "Portaal" */ '@/views/userPortal.vue/Portaal.vue'
      ),
    meta: {
      requiresAuth: true,
      accessRights:["MAKELAAR","BEHEERDER"],
     showFooter: false,
      title: ' MC MCmakelaardij | Welkom bij de gebruikers portaal',
    },
  },
];

export async function setFirebaseToken() {
  const token = (await isLoggedIn())
    ? await firebase.auth().currentUser.getIdToken(true)
    : null;
  if (token) axios.defaults.headers.common['fireToken'] = token;
}

async function onAuthRequired(to, from, next) {
  document.title = to.meta.title || "Mc makelaardij | Makelaarkantoor in Rotterdam"
  await setFirebaseToken();
  if (to.meta.requiresAuth) {
    if (!isLoggedIn()) {
      next({
        path: '/',
        query: { redirect: to.fullPath }
      })
    }
    const response = await axios.get(
      `https://back-end.mcmakelaardij.nl/api/whoami`
    );
    const accessRight = response.data.accessRight;
    if (to.meta.accessRights && !to.meta.accessRights.includes(accessRight)){
      if (["ADMIN", "WERKNEMER"].includes(accessRight)) {
        next("/")
      } else if (["BEHEERDER", "MAKELAAR"].includes(accessRight)) {
        next("/Portaal")
      } else {
        next("/")
      }
      return;
    }

  }
  next()
}

const router = new VueRouter({
  mode: 'history',
  //Dit voor weer naar begin
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(onAuthRequired);

export default router;
